import { useSearchParams } from "@remix-run/react";
import { debounce, omit } from "lodash";
import { useCallback, useMemo } from "react";
import { useRequestUrl } from "./use-request-url";

export const useQueryStrings = () => {
  const url = useRequestUrl();
  const [searchParams, setSearchParams] = useSearchParams();
  const debouncedSetSearchParams = useMemo(() => {
    return debounce(setSearchParams, 500);
  }, [setSearchParams]);

  const addQueryStrings = useCallback(
    (strings: Parameters<typeof setSearchParams>[0]) => {
      const unorderedParams = {
        ...Object.fromEntries(searchParams.entries()),
        ...(strings as any),
      };
      const orderedParams = {
        page: unorderedParams["page"],
        sort: unorderedParams["sort"],
        ...omit(unorderedParams, ["page", "sort"]),
      };

      debouncedSetSearchParams(orderedParams, { replace: true });
    },
    [debouncedSetSearchParams, searchParams]
  );

  const getQueryString = useCallback(
    (key: string) => searchParams.get(key),
    [searchParams]
  );

  const setSort = useCallback(
    (property: string) => {
      if (getQueryString("sort") === property) {
        return addQueryStrings({ sort: `-${property}` });
      }

      addQueryStrings({ sort: property });
    },
    [addQueryStrings, getQueryString]
  );

  const getNewUrlWithSort = useMemo(() => {
    return (property: string) => {
      const newSort =
        getQueryString("sort") === property ? `-${property}` : property;
      url.searchParams.set("sort", newSort);
      return url;
    };
  }, [getQueryString, url]);

  const getNewUrlWith = useMemo(() => {
    return (property: string, value: string) => {
      if (property.toLowerCase() === "page") {
        const num = Number(value);
        if (!Number.isNaN(num)) {
          value = Math.max(1, num).toString();
        }
      }
      const newUrl = new URL(url.toString());
      newUrl.searchParams.set(property, value);
      return newUrl;
    };
  }, [url]);

  return {
    searchParams,
    setSearchParams,
    debouncedSetSearchParams,
    addQueryStrings,
    getQueryString,
    setSort,
    getNewUrlWithSort,
    getNewUrlWith,
  };
};
