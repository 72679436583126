import { useMatches } from "@remix-run/react";
import invariant from "tiny-invariant";

export function useRequestUrl() {
  const matches = useMatches();
  if (typeof window !== "undefined") {
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete("index");
    return urlObj;
  }

  const url: string | undefined = matches.find(
    (match) => match.handle && match.handle.isRoot
  )?.data?.url;
  invariant(url, "useRequestUrl must be used in a route that has a url");
  const urlObj = new URL(url);
  urlObj.searchParams.delete("index");
  return urlObj;
}
